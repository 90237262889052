import { Any } from '@binance/w3w-types'
import * as hash from 'hash.js'

import { bufferToArray, hexToArray, arrayToBuffer } from '../encoding'

import { ModeOfOperationCBC } from './aes'
import { pkcs7 } from './pkcs7'

const SHA256_NODE_ALGO = 'sha256'

export function aesCbcEncrypt(
  iv: Uint8Array,
  key: Uint8Array,
  data: Uint8Array
): Uint8Array {
  const aesCbc = new ModeOfOperationCBC(key, iv)
  const padded = pkcs7.pad(data)
  const encryptedBytes = aesCbc.encrypt(padded)

  return new Uint8Array(encryptedBytes)
}

export function aesCbcDecrypt(
  iv: Uint8Array,
  key: Uint8Array,
  data: Uint8Array
): Uint8Array {
  const aesCbc = new ModeOfOperationCBC(key, iv)
  const encryptedBytes = aesCbc.decrypt(data)
  const padded = new Uint8Array(encryptedBytes)
  const result = pkcs7.unpad(padded)
  return result
}

export function hmacSha256Sign(key: Uint8Array, data: Uint8Array): Uint8Array {
  const result = hash
    .hmac((hash as Any)[SHA256_NODE_ALGO], key)
    .update(data)
    .digest('hex')

  return hexToArray(result)
}

function insecureRandomBytes(e: number): Buffer {
  // eslint-disable-next-line no-var
  for (var t = new Uint8Array(e), n = 0; n < e; ++n) {
    t[n] = Math.floor(256 * Math.random())
  }
  return arrayToBuffer(t)
}

export function randomBytes(length: number): Uint8Array {
  const buf = insecureRandomBytes(length)
  return bufferToArray(buf)
}
