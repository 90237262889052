import { ClientMetadata } from './metadata'

export interface EncryptionPayload {
  data: string
  hmac: string
  iv: string
}

export interface SessionParams {
  approved: boolean
  chainId: number | null
  networkId: number | null
  accounts: string[] | null
  rpcUrl?: string | null
  peerId?: string | null
  peerMeta?: ClientMetadata | null
}

export interface Session {
  connected: boolean
  accounts: string[]
  chainId: string
  relay: string
  key: string
  clientId: string
  clientMeta: ClientMetadata | null
  peerId: string
  peerMeta: ClientMetadata | null
  handshakeId: number
  handshakeTopic: string
}

export enum DisconnectType {
  DisconnectAtWallet = 0,
  DisconnectAtClient = 1,
  NetworkError = 2,
}
