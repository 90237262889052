import {
  hexToArray,
  arrayToHex,
  bufferToArray,
  arrayToBuffer,
} from './encoding'

export function convertHexToArrayBuffer(hex: string): ArrayBuffer {
  return hexToArray(hex).buffer
}

export function convertArrayBufferToHex(
  arrBuf: ArrayBuffer,
  noPrefix?: boolean
): string {
  return arrayToHex(new Uint8Array(arrBuf), !noPrefix)
}

export function convertBufferToArrayBuffer(buf: Buffer): ArrayBuffer {
  return bufferToArray(buf).buffer
}

export function convertArrayBufferToBuffer(arrBuf: ArrayBuffer): Buffer {
  return arrayToBuffer(new Uint8Array(arrBuf))
}

export function convertNumberToHex(num: number | string): string {
  let nextChainId = '0x0'
  if (typeof num === 'number') {
    nextChainId = `0x${num.toString(16)}`
  }
  if (typeof num === 'string') {
    if (num.startsWith('0x')) {
      nextChainId = num
    } else {
      nextChainId = '0x0'
    }
  }

  return nextChainId
}
