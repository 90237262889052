import { ILog, LogData } from '@binance/w3w-types'

const logLevels = {
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 3,
}

const currentLogLevel = logLevels.DEBUG // Set the desired log level here

export const log: ILog = {
  error: function (...args: LogData) {
    if (currentLogLevel >= logLevels.ERROR) {
      console.error('[ERROR]', ...args)
    }
  },
  warn: function (...args: LogData) {
    if (currentLogLevel >= logLevels.WARN) {
      console.warn('[WARN]', ...args)
    }
  },
  info: function (...args: LogData) {
    if (currentLogLevel >= logLevels.INFO) {
      console.info('[INFO]', ...args)
    }
  },
  debug: function (...args: LogData) {
    if (currentLogLevel >= logLevels.DEBUG) {
      console.log('[DEBUG]', ...args)
    }
  },
}
