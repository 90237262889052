import {
  JsonRpcFailure,
  JsonRpcPayload,
  JsonRpcResponse,
  JsonRpcSuccess,
} from '@binance/w3w-types'

export function isJsonRpcPayload(payload: any): payload is JsonRpcPayload {
  return (
    typeof payload === 'object' &&
    'id' in payload &&
    'jsonrpc' in payload &&
    payload.jsonrpc === '2.0'
  )
}

export function isJsonRpcResponse<T = any>(
  payload: JsonRpcPayload
): payload is JsonRpcResponse<T> {
  return (
    isJsonRpcPayload(payload) &&
    (isJsonRpcSuccess(payload) || isJsonRpcError(payload))
  )
}
export function isJsonRpcSuccess<T = any>(
  payload: JsonRpcPayload
): payload is JsonRpcSuccess<T> {
  return 'result' in payload
}

export function isJsonRpcError(
  payload: JsonRpcPayload
): payload is JsonRpcFailure {
  return 'error' in payload
}
