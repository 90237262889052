import { ProviderRpcError, RPC_ERROR, MISC_ERR } from '@binance/w3w-utils'

export function populateError(error: Error) {
  // user reject transaction
  if (error.code === -32050 || error.code === -32000 || error.code === 1000) {
    return new ProviderRpcError(
      RPC_ERROR.REJECT_ERR.code,
      RPC_ERROR.REJECT_ERR.message
    )
  }

  // internal error
  if (error.code === -32603) {
    return new ProviderRpcError(
      MISC_ERR.INTERNAL_ERR.code,
      MISC_ERR.INTERNAL_ERR.message
    )
  }

  // invalid params
  if (error.code === -32600 || error.code === -32602) {
    return new ProviderRpcError(
      RPC_ERROR.INVALID_PARAM.code,
      RPC_ERROR.INVALID_PARAM.message
    )
  }

  return error
}

export function removeQueryParams(url: string) {
  const index = url.indexOf('?')
  return index > -1 ? url.slice(0, index) : url
}
