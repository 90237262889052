// -- Constants ------------------------------------------ //
const ENC_HEX = 'hex'
const ENC_UTF8 = 'utf8'

export function removeHexPrefix(hex: string): string {
  return hex.replace(/^0x/, '')
}

export function hexToArray(hex: string): Uint8Array {
  return bufferToArray(Buffer.from(removeHexPrefix(hex), ENC_HEX))
}

export function arrayToHex(arr: Uint8Array, prefixed = false): string {
  return bufferToHex(arrayToBuffer(arr), prefixed)
}

// -- Uint8Array ----------------------------------------- //

export function bufferToArray(buf: Buffer): Uint8Array {
  return new Uint8Array(buf)
}

export function bufferToHex(buf: Buffer, prefixed = false): string {
  const hex = buf.toString(ENC_HEX)
  return prefixed ? addHexPrefix(hex) : hex
}

export function typedArrayToBuffer(arr: Uint8Array) {
  return ArrayBuffer.isView(arr)
    ? Buffer.from(arr.buffer, arr.byteOffset, arr.byteLength)
    : Buffer.from(arr)
}

export function arrayToBuffer(arr: Uint8Array): Buffer {
  return typedArrayToBuffer(arr)
}

export function addHexPrefix(hex: string): string {
  return hex.startsWith('0x') ? hex : `0x${hex}`
}

export function concatArrays(...args: Uint8Array[]): Uint8Array {
  let result: number[] = []
  args.forEach((arg) => (result = result.concat(Array.from(arg))))
  return new Uint8Array([...result])
}

export function utf8ToArray(utf8: string): Uint8Array {
  return bufferToArray(Buffer.from(utf8, ENC_UTF8))
}
export function utf8ToBuffer(utf8: string): Buffer {
  return Buffer.from(utf8, ENC_UTF8)
}

export function bufferToUtf8(buf: Buffer): string {
  return buf.toString(ENC_UTF8)
}

export function arrayToUtf8(arr: Uint8Array): string {
  return bufferToUtf8(arrayToBuffer(arr))
}

export function utf8ToHex(utf8: string, prefixed = false): string {
  return bufferToHex(utf8ToBuffer(utf8), prefixed)
}
