export class ProviderRpcError extends Error {
  code: number // https://eips.ethereum.org/EIPS/eip-1193#provider-errors

  constructor(code: number, message: string) {
    super(message) // 'Error' breaks prototype chain here
    this.code = code

    /**
     * ref:
     * https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
     * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
     */
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }

  public toString(): string {
    return `${this.message} (${this.code})`
  }
}
