import { IEvents, Any } from '@binance/w3w-types'
import EventEmitter from 'eventemitter3'

export class Events implements IEvents {
  public events: EventEmitter = new EventEmitter()

  public on = (name: string, listener: (...args: Any[]) => void) => {
    return this.events.on(name, listener)
  }

  public once = (name: string, listener: (...args: Any[]) => void) => {
    return this.events.once(name, listener)
  }

  public off = (name: string, listener: (...args: Any[]) => void) => {
    return this.events.off(name, listener)
  }

  public removeListener = (
    name: string,
    listener?: (...args: Any[]) => void
  ) => {
    return this.events.removeListener(name, listener)
  }
  public removeAllListeners = () => {
    return this.events.removeAllListeners()
  }
}
