export const CONNECTION_ERR = {
  CLOSE_MODAL: {
    code: 100001,
    message: '[binance-w3w] User closed modal',
  },
  REJECT_SESSION: {
    code: 100002,
    message: '[binance-w3w] User rejected connection',
  },
  PROVIDER_NOT_READY: {
    code: 100003,
    message: '[binance-w3w] Provider is not ready',
  },
  CONNECTING: {
    code: 100004,
    message: '[binance-w3w] Already processing session request. Please wait',
  },
  CONNECTED: {
    code: 100005,
    message: '[binance-w3w] Already connected',
  },
}

export const RPC_ERROR = {
  REJECT_ERR: {
    code: 200001,
    message: '[binance-w3w] User rejected the operation',
  },
  METHOD_NOT_SUPPORT: {
    code: 200002,
    message: '[binance-w3w] Does not support calling method',
  },
  MISSING_RESPONSE: {
    code: 200003,
    message: '[binance-w3w] Missing JSON RPC response',
  },
  INVALID_PARAM: {
    code: 200004,
    message: '[binance-w3w] Invalid request param',
  },
}

export const MISC_ERR = {
  INTERNAL_ERR: {
    code: 300001,
    message: '[binance-w3w] Internal error',
  },
}
