export const SESSION_KEY = 'connect-session'

export const DOMAINS_KEY = 'connect-domains'

export const DEFAULT_RELAY = 'wss://nbstream.binance.com/wallet-connector'

export const BSC_RPCS = [
  'https://rpc.ankr.com/bsc',
  'https://binance.nodereal.io',
  'https://bscrpc.com',
  'https://bsc-dataseed2.ninicoin.io',
]
