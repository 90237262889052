import EventEmitter from 'eventemitter3'

import { RequestArguments } from './helper'
import { Maybe, Any } from './shared'

export type JsonRpcVersion = '2.0'
export type JsonRpcId = number // Actually: number | string | null

export interface ErrorResponse {
  code: number
  message: string
  data?: string
}

export interface JsonRpcRequest<Method = string, Params = Any> {
  jsonrpc: JsonRpcVersion
  method: Method
  id: JsonRpcId
  params: Params extends undefined ? [] : Params
}

interface JsonRpcResponseBase {
  jsonrpc: JsonRpcVersion
  id: JsonRpcId
}

export interface JsonRpcSuccess<T = Any> extends JsonRpcResponseBase {
  result: Maybe<T>
}
export interface JsonRpcFailure extends JsonRpcResponseBase {
  error: ErrorResponse
}

export type JsonRpcResponse<T = any> = JsonRpcSuccess<T> | JsonRpcFailure

export interface JsonRpcProviderMessage<T = any> {
  type: string
  data: T
}

export type JsonRpcPayload<P = any, R = any> =
  | JsonRpcRequest<P>
  | JsonRpcResponse<R>

export interface JsonRpcValidationResult {
  valid: boolean
  error?: string
}

export interface JsonRpcValidationValid extends JsonRpcValidationResult {
  valid: true
}

export interface JsonRpcValidationInvalid extends JsonRpcValidationResult {
  valid: false
  error: string
}

export type JsonRpcValidation =
  | JsonRpcValidationValid
  | JsonRpcValidationInvalid

export abstract class IEvents {
  events: EventEmitter

  // events
  abstract on(event: string, listener: any): void
  abstract once(event: string, listener: any): void
  abstract off(event: string, listener: any): void
  abstract removeListener(event: string, listener: any): void
}

export interface IJsonRpcConnection extends IEvents {
  connected: boolean
  connecting: boolean
  open(opts?: any): Promise<void>
  close(): Promise<void>
  send(payload: JsonRpcPayload, context?: any): Promise<void>
}
export abstract class IBaseJsonRpcProvider extends IEvents {
  public abstract connect(params?: any): Promise<void>

  public abstract disconnect(): Promise<void>

  public abstract request<Result = any, Params = any>(
    request: RequestArguments<Params>,
    context?: any
  ): Promise<Result>

  // ---------- Protected ----------------------------------------------- //

  protected abstract requestStrict<Result = any, Params = any>(
    request: JsonRpcRequest<Params>,
    context?: any
  ): Promise<Result>
}
export abstract class IJsonRpcProvider extends IBaseJsonRpcProvider {
  connection: IJsonRpcConnection

  public abstract connect(
    connection?: string | IJsonRpcConnection
  ): Promise<void>

  // ---------- Protected ----------------------------------------------- //

  protected abstract onPayload(payload: JsonRpcPayload): void

  protected abstract open(
    connection?: string | IJsonRpcConnection
  ): Promise<void>

  protected abstract close(): Promise<void>
}
