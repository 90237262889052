import { ClientMetadata, Session, Any } from '@binance/w3w-types'
import {
  Events,
  convertHexToArrayBuffer,
  convertArrayBufferToHex,
  getStorage,
  removeStorage,
  setStorage,
  uuid,
} from '@binance/w3w-utils'

import { SESSION_KEY } from './constants'

type JsonRpcCallback = (error: Error | null, response: Any) => void

export class BaseCore extends Events {
  public pending = false

  protected callbacks = new Map<string, JsonRpcCallback>()
  protected clientMeta: ClientMetadata
  protected relay: string

  // -- connection ----------------------------------------------------- //

  protected _key: ArrayBuffer | null = null

  // -- client ----------------------------------------------------- //

  protected _clientId = ''

  // -- peer ----------------------------------------------------- //

  protected _peerId = ''
  protected _peerMeta: ClientMetadata | null = null

  // -- handshake ----------------------------------------------------- //

  protected _handshakeId = 0
  protected _handshakeTopic = ''

  // -- session ----------------------------------------------------- //

  protected _connected = false
  protected _accounts: string[] = []
  protected _chainId = '0x0'

  // -- setters / getters ----------------------------------------------- //

  set key(value: string) {
    if (!value) {
      return
    }
    const key: ArrayBuffer = convertHexToArrayBuffer(value)
    this._key = key
  }

  get key(): string {
    if (this._key) {
      const key: string = convertArrayBufferToHex(this._key, true)
      return key
    }
    return ''
  }

  set clientId(value: string) {
    if (!value) {
      return
    }
    this._clientId = value
  }

  get clientId() {
    let clientId: string | null = this._clientId
    if (!clientId) {
      clientId = this._clientId = uuid()
    }

    return this._clientId
  }

  set peerId(value) {
    if (!value) {
      return
    }
    this._peerId = value
  }

  get peerId() {
    return this._peerId
  }

  set peerMeta(value) {
    this._peerMeta = value
  }

  get peerMeta() {
    return this._peerMeta
  }

  set handshakeTopic(value) {
    if (!value) {
      return
    }
    this._handshakeTopic = value
  }

  get handshakeTopic() {
    return this._handshakeTopic
  }

  set handshakeId(value) {
    if (!value) {
      return
    }
    this._handshakeId = value
  }

  get handshakeId() {
    return this._handshakeId
  }

  get uri() {
    return `wc:${this.handshakeTopic}@1?bridge=${this.relay}&key=${this.key}&scene=bid`
  }

  set chainId(value) {
    this._chainId = value
  }

  get chainId() {
    const chainId: string | undefined = this._chainId
    return chainId
  }

  set accounts(value) {
    this._accounts = value
  }

  get accounts() {
    const accounts: string[] | null = this._accounts
    return accounts
  }

  set connected(_) {
    // empty
  }

  get connected() {
    return this._connected
  }

  get session() {
    return {
      connected: this.connected,
      accounts: this.accounts,
      chainId: this.chainId,
      relay: this.relay,
      key: this.key,
      clientId: this.clientId,
      clientMeta: this.clientMeta,
      peerId: this.peerId,
      peerMeta: this.peerMeta,
      handshakeId: this.handshakeId,
      handshakeTopic: this.handshakeTopic,
    }
  }

  set session(value) {
    if (!value) {
      return
    }
    this._connected = value.connected
    this.accounts = value.accounts
    this.chainId = value.chainId
    this.relay = value.relay
    this.key = value.key
    this.clientId = value.clientId
    this.clientMeta = value.clientMeta
    this.peerId = value.peerId
    this.peerMeta = value.peerMeta
    this.handshakeId = value.handshakeId
    this.handshakeTopic = value.handshakeTopic
  }
}

export class SessionManage extends BaseCore {
  protected getStorageSession() {
    try {
      return getStorage<Session>(SESSION_KEY)
    } catch (error) {
      // ignore error
    }
    return null
  }

  protected setStorageSession() {
    setStorage<Session>(SESSION_KEY, this.session)
  }

  protected removeStorageSession() {
    removeStorage(SESSION_KEY)
  }

  protected manageStorageSession() {
    if (this._connected) {
      this.setStorageSession()
    } else {
      this.removeStorageSession()
    }
  }
}
