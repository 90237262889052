export function getStorage<T>(key: string): T | null {
  let data: T | null = null
  try {
    const rawData = window.localStorage.getItem(key)
    if (rawData) {
      data = JSON.parse(rawData)
    }
  } catch (error) {
    data = null
  }
  return data
}

export function removeStorage(key: string) {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    // ignore error
  }
}

export function setStorage<T>(key: string, data: T) {
  try {
    window.localStorage.setItem(key, JSON.stringify(data))
  } catch (error) {
    // ignore error
  }
}
